.contact__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact__form {
    display: grid;
    grid-template-rows: 1fr 1fr 4fr 1fr;
    row-gap: 1rem;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-1);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-label {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--small-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

#contact-message {
    height: 20rem;

}

.contact__quote {
    /* border: 1px solid black; */
    position: relative;
    display: flex;
    padding: 2vw;
    margin-left: 7vw;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    font-size: medium;
    font-family: 'Lucida Handwriting', cursive;
    width: 300px;
}

.contact__quote::before {
    content: "";
    position: absolute;
    top: 9rem;
    left: -0.25rem;
    right: auto;
    bottom: -0.75rem;
    border-radius: 50%;
    z-index: -1;
    box-shadow:
        0 0 0 10px #f2f2f2,
        0 0 0 15px #d9d9d9,
        0 0 0 20px #bfbfbf;
    animation: wobble 5s infinite;
    width: 19rem;
    height: 19rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--text-color) !important;
}
input::selection {
    background: yourHighlightColor;
}

input::-webkit-selection {
    background: yourHighlightColor;
}

/* Sweet Alert */
.swal-container .swal-popup {
    border-radius: 20px;
    background-color: #f3f3f3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.swal-container .swal-title {
    color: #333;
    font-size: 24px;
}

.swal-container .swal-content {
    color: #666;
}

.swal-container .swal-confirm-button {
    background-color: var(--text-color);
    color: #fff;
    border-radius: 5px;
}



@keyframes wobble {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.contact__quote-author {
    margin-top: 1rem;
    font-size: small;
}

@media screen and (max-width: 992px) {
    .contact__quote {
        margin-right: 4vw;
    }
}

@media screen and (max-width: 768px) {
    .contact__container {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
    }

    .contact__quote {
        /* border: 1px solid black; */
        position: relative;
        display: flex;
        padding: 2vw;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        flex-direction: column;
        font-size: medium;
        font-family: 'Lucida Handwriting', cursive;
        width: 19rem;
    }
    
    .contact__quote::before {
        content: "";
        position: absolute;
        /* top: 9rem;
        left: auto;
        right: auto;
        bottom: -0.75rem; */
        border-radius: 50%;
        z-index: -1;
        
        box-shadow:
            0 0 0 10px #f2f2f2,
            0 0 0 15px #d9d9d9,
            0 0 0 20px #bfbfbf;
        animation: wobble 5s infinite;
        width: 19rem;
        height: 19rem;
    }
}

@media screen and (max-width: 576px) {

    .contact__quote {
        font-size: medium;
    }

    .contact__quote::before {
        content: "";
        position: absolute;
        top: 10rem;
        left: 1rem;
        right: auto;
        bottom: -0.75rem;
        border-radius: 50%;
        z-index: -1;
        
        box-shadow:
            0 0 0 10px #f2f2f2,
            0 0 0 15px #d9d9d9,
            0 0 0 20px #bfbfbf;
        animation: wobble 5s infinite;
        width: 17rem;
        height: 17rem;
    }

}

@media screen and (max-width: 425px) {

    .contact__container .section__subtitle {
        margin-bottom: 0;
    }

}

@media screen and (max-width: 350px) {

    .contact__quote {
        /* border: 1px solid black; */
        position: relative;
        display: flex;
        padding: 2vw;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        flex-direction: column;
        font-size: medium;
        font-family: 'Lucida Handwriting', cursive;
        width: 14rem;
    }

    .contact__quote::before {
        content: "";
        position: absolute;
        top: 11rem;
        left: 2.2rem;
        right: auto;
        bottom: -0.75rem;
        border-radius: 50%;
        z-index: -1;
        
        box-shadow:
            0 0 0 10px #f2f2f2,
            0 0 0 15px #d9d9d9,
            0 0 0 20px #bfbfbf;
        animation: wobble 5s infinite;
        width: 15rem;
        height: 15rem;
    }
}