.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
}

.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list {
    letter-spacing: 0.5px;

    display: flex;
    column-gap: 2rem;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: 0.3s;
}

.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
}

.button__icon {
    margin-left: 0.5rem;
}

.header-hidden {
    opacity: 0;
    filter: blur(6px);
    transform: translateY(-100%);
    transition: all 1s;
}

.header-show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

.nav__logo.hide {
    opacity: 0;
    filter: blur(6px);
    transform: translateX(-100%);
    transition: all 1s;
}

.nav__logo.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    /* transition-delay: 800ms; */
}

.nav__toggle.hide {
    opacity: 0;
    filter: blur(6px);
    transform: translateX(100%);
    transition: all 1s;
}

.nav__toggle.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    /* transition-delay: 800ms; */
}

@media screen and (max-width: 768px) {
    .header {
        top: initial;
        bottom: 0;
    }
    .nav {
        height: var(--header-height);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%; /*this hides the menu below the screen*/
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 0.3s;
    }

    .show-menu {
        bottom: 0; /*this shows the menu again*/
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        left: 1.3rem;
        bottom: 0.5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }

    /* .active-link, */
    .nav__link:hover {
        color: var(--title-color-dark);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

@media screen and (max-width: 350px) {
    /* .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    } */
    .header {
        top: initial;
        bottom: 0;
    }
    .nav {
        height: var(--header-height);
        
    }
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list {
        column-gap: 0;
    }
}


