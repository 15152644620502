@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Press+Start+2P&family=Tiny5&display=swap');

:root {
  --header-height: 3rem;

  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 24%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 40%);
  --body-color: hsl(var(--hue), var(--sat), 96%);
  --container-color: #fff;


  --body-font: 'Poppins', sans-serif;

  --big-font-size: 3.5rem;
  --h1-font-size: 2.5rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.825rem;
  --tiny-font-size: 0.64rem;

  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

}

  @media screen and (max-width: 992px) {
    :root {
      --big-font-size: 2.75rem;
      --h1-font-size: 1.5rem;
      --h2-font-size: 1.25rem;
      --h3-font-size: 1rem;
      --normal-font-size: 0.938rem;
      --small-font-size: 0.813rem;
      --smaller-font-size: 0.75rem;
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth
  }

  body,
  button,
  input, 
  textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
  }

  body {
    background-color: var(--body-color);
    color: var(--text-color);  
  }

  *::selection {
    background: lightgray !important;
  }

  *::-webkit-selection {
    background: lightgray !important;
  }

  h1, h2 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
  }

  h3 {
    font-size: var(--h3-font-size);
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .section {
    font-size: var(--h1-font-size);
    margin-top: 10rem;
    padding-top: 2rem;
  }

  .section__title {
    position: relative;
    text-align: center;
    padding: 0.5rem;
    margin: 5rem;
    overflow: hidden;
  }

  .container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
  }

  .grid {
    display: grid;
    gap: 1.5rem;
  }

  .button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    margin-right: 1rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
  }

  .button:hover {
    background-color: var(--title-color-dark);
  }

  .button--flex {
    display: inline-flex;
    align-items: center;
  }

  .section__title span.hide {
    display: inline-block;
    filter: blur(4px);
    opacity: 0;
    transform: translateY(100%);
    transition-delay: 0.1s;
  }

  .section__title span.show {
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
    transition: all 1s;
    transition-delay: 0.1s;
  }

  /* .subsection.hide {
    filter: blur(6px);
    transition: all 1.5s;
    transition-delay: 0.5s;
  }

  .subsection.show {
    filter: blur(0);
    transition: all 1.5s;
    transition-delay: 0.5s;
  } */

  @media screen and (max-width: 992px) {
    .container {
      padding-left: var(--mb-1-5);
      padding-right: var(--mb-1-5);
    }

    .button {
      padding: 1rem 1.75rem;
    }

    .button__icon {
      width: 22px;
      height: 22px;
    }
  }

  @media screen and (max-width: 768px) {
    body {
      margin: 0 0 var(--header-height) 0;
    }



    .section {
      padding: 2rem 0 4rem;
    }

    .portfolio.section {
      /* display: flex;
      flex-direction: column; */
      /* height: 5200px; */
    }

    .section__subtitle {
      margin-bottom: var(--mb-3);
    }
  }

  @media screen and (max-width: 576px) {
    :root {
      --big-font-size: 2.25rem;
      text-align: center;
    }
    .container {
      margin: 0;
      padding-left: var(--mb-1);
      padding-right: var(--mb-1);
    }
    .button {
      padding: 1.25rem;
      margin: 1rem;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 450px) {
    .section {
      /* padding: 0; */
      /* margin: 0; */
    }

    .section__title {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

  }

  @media screen and (max-width: 350px) {
    .button {
      padding: 1.25rem;
      margin-right: 0;
      margin-bottom: 1rem;
    }

  }