.preloader {
    height: 100vh;
    width: 100vw;
    background: var(--body-color);
    color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    height: 50px;
    width: auto;
    font-size: 50px;
    font-weight: 700;
    overflow: hidden;
    color: black;
}