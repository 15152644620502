.portfolio {
    margin-top: 10rem;
    overflow-x: hidden;
    /* overflow: hidden; */
    /* background: black; */
    /* color: white; */
}

.portfolio .section__title {
    margin-bottom: 0;
}

#projects {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.project-container {
    margin-top: 5rem;
    margin-bottom: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* max-height: 60vh; */
}

.project-image {
    order: 0;
    width: clamp(50vw, 60vw, 75vw);
}

#tweety-bird {
    cursor: pointer;
}

#ccs-website {
    cursor: pointer;
}

.ccs-expedited img {
    cursor: pointer;
}

.project-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 35vw;
    max-height: 80%;
    order: 1;
}

.project {
    max-height: 100%;
    max-width: 100%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.project-title {
    position: relative;
    width: 100%;
    padding-right: 2rem;
    padding-top: 0.5rem;
    text-align: right;
    font-size: 2vw;
}

.project-title p {
    font-weight: normal;
    width: 100%;
    font-size: 1.4vw;
    margin-top: 1rem;
}

.project-description {
    position: relative;
    width: 75%;
    align-self: flex-end;
    text-align: right;
    padding-right: 2rem;
    font-size: 0.75rem;
    line-height: clamp(0.75vw, 2rem, 5vw);
}

.project-description.tweety {
    font-size: 1rem;
    /* line-height: clamp(1vw, 2vw, 5vw); */
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.project-description.ccs-expedited {
    font-size: 1rem;
    /* line-height: clamp(1vw, 2vw, 5vw); */
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.project-description.hrf {
    font-size: 1rem;
    /* line-height: clamp(1vw, 2vw, 5vw); */
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.project-description.friendslist {
    font-size: 1rem;
    /* line-height: clamp(1vw, 2vw, 5vw); */
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.project-description.activity {
    font-size: 1rem;
    /* line-height: clamp(1vw, 2vw, 5vw); */
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.project-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 2rem;
    font-size: medium;
    color: black;
}

.project-button {
    position: relative;
    font-size: clamp(1vw, 1.5vw, 1.5vw);
    color: black;
    padding: 0.5rem;

    border: 2px solid transparent;
    border-radius: 12px;
    transition: border 2s ease-in, border 0.3s ease-out;
}

.project-button:hover {
    border: 2px solid black;
}

.hrf button {
    background-color: inherit;
}





.project.hide {
    filter: blur(6px);
    transform: translateX(-100%);
    transition: all 0.5s;
}

.project.show {
    filter: blur(0);
    transform: translateX(0);
    transition: all 2s;
}

.project-title.hide {
    filter: blur(6px);
    transform: translateX(100%);
    transition: all 2s;
}

.project-title.show {
    filter: blur(0);
    transform: translateX(0);
    transition: all 2s;
    transition-delay: 1s;
}

.project-description.hide {
    filter: blur(6px);
    transform: translateX(100%);
    transition: all 2s;
}   

.project-description.show {
    filter: blur(0);
    transform: translateX(0);
    transition: all 2s;
    transition-delay: 2s;
}

.project-buttons.hide {
    filter: blur(6px);
    transform: translateX(30%);
    transition: all 2s;
}

.project-buttons.show {
    filter: blur(0);
    transform: translateX(0);
    transition: all 2s;
    transition-delay: 3s;
}

@media screen and (max-width: 992px) {

    .portfolio.section {
        overflow-y: hidden;
    }

    .project-container {
        flex-direction: column;
        /* justify-content: space-between; */
        /* height: 80vh; */
        gap: 2rem;
    }

    .project-image {
        width: 75vw;
    }
    
    .project-info {
        gap: 1.5rem;
        max-width: 85vw;
        /* height: 100%; */
        order: 1;
    }
    
    .project-title {
        padding: 0.5rem 2rem 0 1rem;
        text-align: left;
        /* font-size: clamp(1vmin, 3vmin, 4vmin); */
        font-size: 2rem;
    }
    
    .project-description {
        /* position: relative; */
        /* width: 75%; */
        align-self: flex-start;
        text-align: left;
        /* padding-right: 2rem; */
        padding-left: 1rem;
        font-size: 0.9rem;
        line-height: clamp(2vw, 7vw, 9vw);
    }

    .project-description.tweety {
        font-size: 0.9rem;
        line-height: 2rem;
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .project-description.ccs-expedited {
        font-size: 0.9rem;
        line-height: clamp(2vw, 1.5rem, 9vw);
        width: 95%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .project-description.hrf {
        font-size: 0.9rem;
        line-height: clamp(2vw, 1.5rem, 9vw);
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .project-description.friendslist {
        font-size: 0.9rem;
        line-height: 2rem;
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .project-description.activity {
        font-size: 0.9rem;
        line-height: 2rem;
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .project-title p {
        font-size: 1rem;
    }
    
    .project-buttons {
        /* display: flex; */
        /* flex-direction: column; */
        align-items: flex-start;
        padding-left: 0.5rem;
    }
    
    .project-button {
        /* position: relative; */
        font-size: 1.5rem;
        
    }

    .project-title.hide {
        filter: blur(6px);
        transform: translateX(-100%);
        transition: all 2s;
    }
    
    .project-title.show {
        filter: blur(0);
        transform: translateX(0);
        transition: all 2s;
        transition-delay: 0.5s;
    }
    
    .project-description.hide {
        filter: blur(6px);
        transform: translateX(-100%);
        transition: all 2s;
    }   
    
    .project-description.show {
        filter: blur(0);
        transform: translateX(0);
        transition: all 2s;
        transition-delay: 1s;
    }
    
    .project-buttons.hide {
        filter: blur(6px);
        transform: translateX(-30%);
        transition: all 2s;
    }
    
    .project-buttons.show {
        filter: blur(0);
        transform: translateX(0);
        transition: all 2s;
        transition-delay: 1.5s;
    }
}

@media screen and (max-width: 776px ) {
    project-button:hover {
        border: none;
    }
}

@media screen and (max-width: 500px) {
    .portfolio {
        margin-top: 0;
    }

    .project-image {
        width: 90vw;
    }

    .project-button {
        /* position: relative; */
        font-size: 1rem;
        padding-left: 0.5rem;
    }
}