.home {
    margin-top: 0;
}

.home__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 100px;
    margin-left: 50px;
}

/* .home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
    font-size: var(--small-font-size);
} */

.home__social {
    position: absolute;
    top: 35%;
    left: 20px;
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1.5rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover {
    color: var(--first-color-dark);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__subtitle {
    letter-spacing: 2px;

    position: relative;
    font-size: var(--h3-font-size);
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

/* .home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
} */

.home__description {
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.75;
    
    max-width: 400px;
    margin-bottom: var(--mb-3);

    border: 3px solid gray;
    padding: 1rem;
    border-radius: 15px;
}

.home__container .button {
    font-size: 1rem;
}

.home__title.hide {
    opacity: 0;
    transition: all 2s;
}

.home__title.show {
    opacity: 1;
}

.home__subtitle.hide {
    opacity: 0;
    transition: all 2s;
}

.home__subtitle.show {
    opacity: 1;
}

.home__description.hide {
    opacity: 0;
    transition: all 2s;
}

.home__description.show {
    opacity: 1;
}

.home__container .button.hide {
    opacity: 0;
    filter: blur(5px);
    transition: all 1.75s;
}

.home__container .button.show {
    opacity: 1;
    filter: blur(0);
}

.home__img {
    background: url(../../assets/profile.jpg);
    background-position: center;
    background-size: cover;
    order: 0;
    z-index: 1;
    border-radius: 50%;

    width: 400px;
    height: 400px;
}

.home__img.hide {
    filter: blur(2px);
    transition: all 3s;
}

.home__img.show {
    filter: blur(0);
}

.home__scroll {
    margin: 10rem;
}

.home__scroll {
    margin-left: 9.25rem;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

.home__scroll-name {
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow {
    font-size: 1.2rem;
    color: var(--title-color)
}


@media screen and (max-width: 992px) {

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home__description {
        font-size: 0.85rem;
        max-width: 400px;
        margin-bottom: var(--mb-3);

    }

    .home__container .button {
        font-size: 1rem;
    }

    .home__data {
        width: 400px;
    }

    .home__img {
        width: 250px;
        height: 250px;
        /* box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%); */
    }

    .home__scroll {
        margin-left: 7.5rem;
    }

    .home__container .button {
        font-size: 0.75rem;
    }

    .button__icon {
        width: 15px;
    }
}




@media screen and (max-width: 768px) {
    .home__container {
        margin: 0;
        margin-top: 50px;
        flex-direction: column;
    }

    .home__social {
        top: 175px;
    }

    .home__data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        margin: 50px;
        height: 30rem;
    }

    .home__title {
        text-align: center;
    }

    .home__subtitle {
        text-align: center;
        padding: 0;
    }

    .home__subtitle::before {
        content: '';
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--text-color);
        left: 0;
        top: 0.85rem;
    }

    .home__subtitle::after {
        content: '';
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--text-color);
        right: 0;
        top: 0.85rem;
    }

    .home__img {
        width: 300px;
        height: 300px;
    }

    .home__data {
        grid-column: 1/3;
    }

    .home__container .button {
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .home__scroll {
        display: none;
    }
}



@media screen and (max-width: 576px) {

    .home__description {
        margin: 0;
    }

    .home__social {
        top: 140px;
    }

    .home__data {
        width: 300px;
        height: 400px;
    }

    .home__subtitle::before {
        width: 50px;
    }

    .home__subtitle::after {
        width: 50px;
    }

    .home__img {
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 5px rgb(255 255 255 / 30%);
    }

    .home__scroll {
        margin-left: 7.5rem;
    }

    .home__container .button {
        font-size: 0.75rem;
        width: 200px;
        align-self: center;
    }

    .button__icon {
        width: 15px;
    }

    .nav {
        margin-left: 25px;
        margin-right: 25px;
    }

}

@media screen and (max-width: 425px) {
    .home__social {
        top: 110px;
    }
}

@media screen and (max-width: 350px) {
    .home__title {
        font-size: 40px;
    }

    .home__subtitle {
        font-size: 20px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .home__img {
        width: 180px;
        height: 180px;
    }

    .home__subtitle::before {
        display: none;
    }

    .home__subtitle::after {
        display: none;
    }

    .home__container .button {
        margin-top: 1rem;
        font-size: 0.75rem;
        width: 200px;
        align-self: center;
    }
}

/* @media screen and (max-width: 500px) {
    .say-hello {
        font-size: 1rem;
    }

    .view-my-work {
        width: 80px;
    }
} */